.hero {
  display: flex;
  width: 100%;
  height: calc(100vh - 3.125rem);
  max-height: calc(100vh - 3.125rem);
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--spc-m) var(--spc-xs) var(--spc-xl);
  max-width: 100%;
  height: 100%;
  z-index: var(--z-content);
  position: relative;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image img {
  max-width: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (min-width: 64rem) {
  .hero {
    max-height: calc(100vh - 3.3125rem);
    height: calc(100vh - 3.3125rem);
  }

  .content {
    padding: var(--spc-xl) var(--spc-l);
  }
}
