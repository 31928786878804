.thanks {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--spc-xxl) var(--spc-xs) var(--spc-3xxl);
  max-width: var(--measure-small);
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.thanks h2 {
 margin: 0 0 var(--spc-m);
}

.thanks p {
  font-size: 18px;
  max-width: unset;
}
