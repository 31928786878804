.app {
  position: relative;
}

.fullScreen {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  width: 100vw;
}

.content {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 1fr;
  max-width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;
}

.heroBackground {
  height: 110% !important;
  object-position: 12% 80% !important;
}

.heroBackground img {
  z-index: var(--z-background);
}

.heroBackground::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #101118;
  opacity: 0.32;
  z-index: var(--z-background-overlay);
}

.date {
  margin: var(--spc-s) 0 var(--spc-s);
  text-shadow: 0 2px 4px var(--dark-50);
  text-align: center;
}
.date.large {
  display: none;
}

.logo {
  max-width: 17rem;
  width: 100%;
}

.button {
  margin: var(--spc-xl) 0 0;
  justify-self: center;
  align-self: flex-end;
}

.formWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: var(--spc-l) var(--spc-xs) var(--spc-xl);
}

.form {
  display: grid;
  width: 100%;
  max-width: var(--measure-small);

  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  gap: var(--spc-xl) var(--spc-m);
}

@media screen and (min-width: 64rem) {
  .content {
    grid-template: repeat(3, auto) / 1fr;
    height: auto;
  }

  .heroBackground img {
    height: 100% !important;
    object-position: center !important;
  }

  .date {
    margin: var(--spc-m) 0 var(--spc-m);
  }

  .date.small {
    display: none;
  }

  .date.large {
    display: block;
  }

  .logo {
    max-width: 35.125rem;
  }

  .formWrapper {
    margin: var(--spc-2xxl) 0 var(--spc-3xxl);
    width: 100%;
  }

  .form {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: var(--spc-xl) var(--spc-m);

    padding: 0 var(--spc-m);
  }

  .form label:first-of-type {
    grid-column: 1 / span 2;
  }
}

@media screen and (min-width: 120rem) {
  .content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .button {
    align-self: center;
  }
}
