.navigation {
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--spc-xs);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.mobile {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: fixed;
  top: -200vh;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: var(--z-content);
  background: var(--white);
  transition: all 0.3s ease-in-out;
}

.mobile.open {
  top: 0;
  transition: all 0.3s ease-in-out;
}

.list {
  display: none;
  padding: var(--spc-s) 0;
  margin: 0;
  justify-content: center;
  list-style: none;
}

.mobile .list {
  display: flex;
  padding: var(--spc-xl) var(--spc-m);
  flex-flow: column nowrap;
  align-items: flex-start;
}

.item {
  margin: 0 var(--spc-m);
}

.mobile .item {
  margin: var(--spc-s) 0;
}

.mobile .langs {
  flex-flow: row nowrap;
  padding: var(--spc-xl) 0 var(--spc-m);
}

.lang {
  margin: 0 0.75rem;
}

.mobile .lang {
  margin: 0 var(--spc-s);
}

.lang :global(.link) {
  font-size: 1rem;
  color: var(--dark-50);
}

.lang :global(.link):hover {
  color: var(--dark);
}

.lang :global(.link).selected {
  color: var(--dark-85);
  cursor: default;
  pointer-events: none;
}

@media screen and (min-width: 64rem) {
  .navigation {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--spc-l);
  }

  .list {
    display: flex;
    flex-flow: row nowrap;
  }
}
