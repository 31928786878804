:root {
  --dark: #101118;
  --dark-85: rgba(16, 17, 24, 0.85);
  --dark-50: rgba(16, 17, 24, 0.5);
  --dark-sky-blue: #37bce1;
  --white: #ffffff;
  --grey: #bfbfbf;
  --gainseboro: #d8d8d8;

  --measure-paragraph: 22.9375rem;
  --measure-small: 43.625rem;
  --measure-total: 68.75rem;

  --spc-xxxs: 0.25rem; /* 4px */
  --spc-xxs: 0.5rem; /* 8px */
  --spc-xs: 0.75rem; /* 12px */
  --spc-s: 1rem; /* 16px */
  --spc-m: 2rem; /* 32px */
  --spc-l: 3.5rem; /* 56px */
  --spc-xl: 5rem; /* 80px */
  --spc-xxl: 7.5rem; /* 120px */
  --spc-2xxl: 10rem; /* 160px */
  --spc-3xxl: 12.5rem; /* 200px */

  --z-background: -100;
  --z-background-overlay: -10;
  --z-content: 1;
  --z-menu: 100;
}

* {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0;
  padding: 0;

  text-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);
  font-family: FORQUE, sans-serif;
  font-size: 6.25rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}

h2 {
  margin: 0;
  padding: 0;
  font-family: 'FORQUE', sans-serif;
  font-size: 3rem;
  font-weight: normal;
  line-height: 1;
  color: var(--dark);
  text-transform: uppercase;
}

@media screen and (min-width: 21rem) {
  h2 {
    font-size: 4.375rem;
  }
}

@media screen and (min-width: 64rem) {
  h1 {
    font-size: 8.75rem;
  }

  h2 {
    font-size: 5rem;
    line-height: normal;
  }
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-weight: 900;
  color: var(--white);
}

h4 {
  margin: 0;
  padding: 0;
  font-family: 'FORQUE', serif;
  font-size: 2.5rem;
  color: var(--dark);
  font-weight: normal;
  text-transform: uppercase;
}

h5 {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--white);
}

p {
  margin: 0;
  max-width: var(--measure-paragraph);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.white {
  color: var(--white);
}

.dark {
  color: var(--dark);
}

.upper {
  text-transform: uppercase;
}

.link {
  padding: 0;
  margin: 0;
  border: 0;

  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  color: var(--dark-85);
  text-decoration: none;
  text-transform: uppercase;
  background: none;
}

.link:focus {
  color: var(--dark-50);
  cursor: pointer;
  outline: none;
}

.button {
  padding: 1rem 4rem;

  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.5);
  border: 0;
  background-color: var(--white);

  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--dark);
  transition: all 0.1s ease-in-out;
}

.button:active {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.5);
  border: 0;
  background-color: var(--white);

  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--dark);
  transition: all 0.1s ease-in-out;
}

.button-small {
  padding: var(--spc-xxs) var(--spc-s);
  font-size: 1rem;
  text-transform: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

@media screen and (min-width: 64rem) {
  .link:hover {
    color: var(--dark-50);
    cursor: pointer;
    outline: none;
  }

  .button:hover {
    transform: scale(1.1) rotate(4deg);
    cursor: pointer;

    transition: all 0.1s ease-in-out;
  }
}
