.keywords {
  display: grid;
  margin: var(--spc-l) auto var(--spc-xl);
  padding: 0 var(--spc-xs);
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'content'
    'img';
  max-width: calc(var(--measure-total) - 2 * var(--spc-l));
  justify-items: center;
  align-items: center;
}

.reverse {
  grid-template-areas: 'content' 'img';
}

.content {
  grid-area: content;
  display: flex;
  flex-flow: column nowrap;
  max-width: 367px;
  margin: var(--spc-s) 0 var(--spc-l);
}

.img {
  grid-area: img;
  width: 100%;
  object-fit: contain;
  object-position: center;
  max-width: 30rem;
}

@media screen and (min-width: 64rem) {
  .keywords {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'content . img';

    margin: var(--spc-2xxl) auto var(--spc-3xxl);
    padding: 0 var(--spc-l);
  }

  .content {
    margin: var(--spc-s) 0;
    align-items: flex-start;
  }

  .reverse {
    grid-template-areas: 'img . content';
  }

  .img {
    max-width: 25rem;
  }
}
