.about {
  display: flex;
  flex-flow: column-reverse nowrap;
  padding: var(--spc-l) var(--spc-xs) var(--spc-xl);
  max-width: var(--measure-total);
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.content {
  margin: var(--spc-m) 0 0;
  font-size: 1rem;
  color: var(--dark);
}

.logo {
  margin: var(--spc-m) 0 0;
  width: 100%;
  max-width: 30rem;
  flex: 1;
  position: relative;
}

@media screen and (min-width: 64rem) {
  .about {
    flex-flow: row nowrap;
    padding: var(--spc-xxl) 0 var(--spc-2xxl);
  }

  .logo {
    min-width: 26.5625rem;
    margin: 0 11.4375rem 0 0;
    max-width: unset;
  }
}
