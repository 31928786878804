.label {
  display: flex;
  flex-flow: column nowrap;
  font-size: 1.375rem;
  font-weight: bold;
  color: var(--dark);
  background: transparent;
  text-align: left;
}

.input {
  padding: 0 var(--spc-xxs);
  font-size: 22px;
  line-height: 1.09;
  color: var(--dark);
  border: 0;
  background: transparent;
  border-radius: 0;
  border-bottom: 3px solid var(--dark-sky-blue);
}

.input:focus {
  outline: none;
  border-bottom: 3px solid var(--dark-50);
  transition: all 0.3s ease-in-out;
}

.label .input {
  margin-top: var(--spc-s);
}
