.section {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto 1fr;
  height: 38.75rem;
  position: relative;
}

.background {
  grid-row: 1 / -1;
  grid-column: 1 / -1;
}

.action {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  grid-row: 1;
  grid-column: 2 / span 1;
  justify-self: center;
  align-self: center;
  z-index: var(--z-content);
}

.title {
  text-align: center;
  margin: 0 0 var(--spc-l);
  text-shadow: 0 2px 4px var(--dark-50);
}

.image {
  width: 100%;
  height: 100%;
}

.image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: var(--z-background);
}

@media screen and (min-width: 64rem) {
  .title {
    text-align: left;
  }
}

@media screen and (min-width: 180rem) {
  .section {
    width: 100%;
    height: unset;
  }
}
