.burger {
  background: none;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  z-index: var(--z-menu);
  position: relative;
}

.burger:focus {
  outline: none;
}

.burger span {
  position: relative;
  margin-top: 9px;
  margin-bottom: 9px;

  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}
.burger span,
.burger span::before,
.burger span::after {
  display: block;
  width: 30px;
  height: 3px;
  background-color: var(--dark);
  outline: 1px solid transparent;

  transition-property: background-color, transform;

  transition-duration: 0.3s;
}
.burger span::before,
.burger span::after {
  position: absolute;
  content: '';
}
.burger span::before {
  top: -9px;
}
.burger span::after {
  top: 9px;
}
.burger.active span {
  background-color: transparent;
}
.burger.active span::before {
  transform: translateY(9px) rotate(45deg);
}
.burger.active span::after {
  transform: translateY(-9px) rotate(-45deg);
}
.burger.active span:before,
.burger.active span:after {
  background-color: var(--dark);
}
.burger:hover {
  cursor: pointer;
}

@media screen and (min-width: 64rem) {
  .burger {
    display: none;
  }
}
